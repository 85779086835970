





























import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { ArticleService } from '@/includes/services/ArticleService'
import PageTitle from '@/components/PageTitle.vue'
import Embed from './custom-editorjs-plugins/embed/index'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import Component from 'vue-class-component'
import EditorJS, { OutputBlockData, OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Table from '@editorjs/table'
import SimpleImage from '@editorjs/simple-image'
import Warning from '@editorjs/warning'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import Link from '@editorjs/link'
import List from '@editorjs/list'
import CheckList from '@editorjs/checklist'
import InlineConde from '@editorjs/inline-code'
import UnderLine from '@editorjs/underline'
import Delimiter from '@editorjs/delimiter'
import TextVariantTune from '@editorjs/text-variant-tune'
import NestedList from '@editorjs/nested-list'
import { Mixins } from 'vue-property-decorator'
import { DateTimeFormatOptions } from 'vue-i18n'
import { mapGetters } from 'vuex'
//@ts-ignore
import he from 'he'

@Component({
  components: {
    PageTitle,
    CenteredColumnLayout
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class Articles extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  getBoardById!:GetBoardByIdType

  articleTitle: string = ''

  articleKey: string = ''

  disabledSaveButton: boolean = true

  article: OutputData | {} = {}

  isLoading = false

  editor = new EditorJS({
    holder: 'editorjs',
    tools: {
      header: Header,
      table: Table,
      simpleImage: SimpleImage,
      warning: {
        class: Warning,
        inlineToolbar: true,
        config: {
          titlePlaceholder: this.$t('editorjs_tool_warning_title_placeholder').toString(),
          messagePlaceholder: this.$t('editorjs_tool_warning_message_placeholder').toString(),
        }
      },
      quote: Quote,
      marker: Marker,
      list: List,
      embed: Embed as any,
      link: {
        class: Link,
        config: {
          endpoint: 'https://api.presscode.app/cabinet/v1/tg/geturlinfo'
        }
      },
      checkList: CheckList,
      inlineCode: InlineConde,
      underLine: UnderLine,
      delimiter: Delimiter,
      textVariant: TextVariantTune,
      nestedList: NestedList
    },
    i18n: {
      messages: {
        ui: {
          blockTunes: {
            toggler: {
              'Click to tune': this.$t('editorjs_blocktunes_togle_click').toString(),
              'or drag to move': this.$t('editorjs_blocktunes_togle_drag').toString()
            },
          },
          toolbar: {
            toolbox: {
              Add: this.$t('editorjs_toolbar_add').toString()
            }
          },
          inlineToolbar: {
            converter: {
              'Convert to': this.$t('editorjs_inline_toolbar_converter').toString()
            }
          }
        },
        toolNames: {
          Text: this.$t('editorjs_toolname_text').toString(),
          Heading: this.$t('editorjs_toolname_heading').toString(),
          List: this.$t('editorjs_toolname_list').toString(),
          Warning: this.$t('editorjs_toolname_warning').toString(),
          Checklist: this.$t('editorjs_toolname_check').toString(),
          Quote: this.$t('editorjs_toolname_quote').toString(),
          Delimiter: this.$t('editorjs_toolname_delimiter').toString(),
          Table: this.$t('editorjs_toolname_table').toString(),
          Link: this.$t('editorjs_toolname_link').toString(),
          Marker: this.$t('editorjs_toolname_marker').toString(),
          Bold: this.$t('editorjs_toolname_bold').toString(),
          Italic: this.$t('editorjs_toolname_italic').toString(),
          InlineCode: this.$t('editorjs_toolname_monospace').toString(),
          UnderLine: this.$t('editorjs_toolname_underline').toString()
        },
        tools: {
          link: {
            Link: this.$t('editorjs_tool_link').toString()
          },
          stub: {
            'The block can not be displayed correctly.': this.$t('editorjs_tool_stub').toString()
          },
          embed: {
            'Enter a caption': this.$t('editorjs_tool_embed').toString()
          },
        },
        blockTunes: {
          delete: {
            Delete: this.$t('editorjs_block_tunes_delete').toString(),
          },
          moveUp: {
            'Move up': this.$t('editorjs_block_tunes_moveup').toString(),
          },
          moveDown: {
            'Move down': this.$t('editorjs_block_tunes_movedown').toString(),
          },
          textVariant: {
            Details: this.$t('editorjs_block_tunes_text_variant_details').toString(),
            Citation: this.$t('editorjs_block_tunes_text_variant_citation').toString(),
            'Call-out': this.$t('editorjs_block_tunes_text_variant_callout').toString()
          }
        }
      }
    },
    onChange: ((api, event: CustomEvent<any>) => {
      api.saver.save().then((output: OutputData) => {
        this.checkIsEmptyEditorData(output)
      })
    }),
    tunes: [ 'textVariant' ]
  })

  isEmpty(block: OutputBlockData): boolean | void {
    let type = block.type
    let data = block.data
    if (type == ('paragraph' || 'header')) {
      if ((he.decode(data.text)).trim().length == 0) {
        return true
      } else {
        return false
      }
    } else if (type == 'table') {
      if (data.content.length == 0) {
        return true
      } else {
        return false
      }
    } else if (type == 'simpleImage') {
      if (he.decode(data.url).trim().length == 0) {
        return true
      } else {
        return false
      }
    } else if (type == 'warning') {
      if (he.decode(data.title).trim().length == 0 || he.decode(data.message).trim().length == 0) {
        return true
      } else {
        return false
      }
    } else if (type == 'quote') {
      if (he.decode(data.text).trim().length == 0 || he.decode(data.caption).trim().length == 0) {
        return true
      } else {
        return false
      }
    } else if (type == 'list') {
      if (data.items.length != 0) {
        data.items.forEach(item => {
          if (((he.decode(item)).trim()).length != 0) {
            return false
          }
        })
      } else {
        return true
      }
    } else if (type == 'checkList') {
      if (data.items.length != 0) {
        data.items.forEach(item => {
          if (((he.decode(item.text)).trim()).length != 0) {
            return false
          }
        })
      } else {
        return true
      }
    } else if (type == 'delimiter') {
      return true
    } else if (type == 'nestedList') {
      if (data.items.length != 0) {
        data.items.forEach(item => {
          if (((he.decode(item.content)).trim()).length != 0) {
            return false
          }
        })
      } else {
        return true
      }
    } else if (type == 'embed') {
      if (data.source) {
        return data.source.trim().length !== 0
      } else {
        return true
      }
    }
  }

  checkIsEmptyEditorData(data: OutputData): void {
    if (data.blocks.length != 0) {
      let checkIsEmptyOutput = true
      data.blocks.forEach((block: OutputBlockData) => {
        if (!this.isEmpty(block)) {
          checkIsEmptyOutput = false
        }
      })
      this.disabledSaveButton = checkIsEmptyOutput
    } else {
      this.disabledSaveButton = true
    }
  }

  save(): void {
    this.isLoading = true
    this.editor.save()
      .then((output: OutputData) => {
        if (!this.articleTitle.trim()) {
          let headers: Array<{ block: OutputBlockData, index: number }> = []
          output.blocks.forEach((block, index) => {
            if (block.type == 'header') {
              headers.push({ block: block, index: index })
            }
          })
          let H1 = headers.find(item => item.block.data.level == 1)
          let H2 = headers.find(item => item.block.data.level == 2)
          let H3 = headers.find(item => item.block.data.level == 3)
          let H4 = headers.find(item => item.block.data.level == 4)
          let H5 = headers.find(item => item.block.data.level == 5)
          let H6 = headers.find(item => item.block.data.level == 6)
          let paragraph = output.blocks.find(item => item.type == 'paragraph')
          if (H1) {
            this.articleTitle = H1.block.data.text
          } else if (H2) {
            this.articleTitle = H2.block.data.text
          } else if (H3) {
            this.articleTitle = H3.block.data.text
          } else if (H4) {
            this.articleTitle = H4.block.data.text
          } else if (H5) {
            this.articleTitle = H5.block.data.text
          } else if (H6) {
            this.articleTitle = H6.block.data.text
          } else if (paragraph) {
            this.articleTitle = this.abbreviation(paragraph.data.text)
          } else {
            let date = new Date
            let dateOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
            this.articleTitle = this.$t('get_article_title').toString() + date.toLocaleDateString(this.$i18n.locale, dateOptions as DateTimeFormatOptions)
          }
        }
        if (!this.articleKey) {
          this.createArticle(output)
        } else {
          this.updateArticle(output)
        }
      })
      .catch(errorNotification)
  }

  createArticle(output: OutputData): void {
    this.$store.dispatch('addArticle', { title: this.articleTitle, article: output })
      .catch(errorNotification)
      .finally(() => {
        this.$router.push({ name: 'articles_list', params: { id: this.$route.params.id } })
        this.isLoading = false
      })
  }

  updateArticle(output: OutputData): void {
    this.$store.dispatch('updateArticle', { key: this.articleKey, title: this.articleTitle, article: output })
      .catch(errorNotification)
      .finally(() => {
        this.$router.push({ name: 'articles_list', params: { id: this.$route.params.id } })
        this.isLoading = false
      })

  }

  abbreviation(text: string): string {
    let textRep: string = (he.decode(text.replace(`<br>`, ''))).trim()

    if (textRep.length > 27) {
      return textRep.slice(0, 25) + '...'
    } else {
      return textRep
    }
  }

  async fetchConfig(): Promise<void> {
    try {
      this.isLoading = true
      const data: { title: string, article: OutputData, key: string } = await ArticleService.getArticle('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        key: this.$route.query.articleKey.toString()
      })
      this.article = data.article
      this.articleTitle = data.title
      this.articleKey = data.key
    } catch (error: any) {
      errorNotification(error)
    } finally {
      await this.editor.render(this.article as OutputData)
      this.checkIsEmptyEditorData(this.article as OutputData)
      this.isLoading = false
    }
  }

  mounted(): void {
    if (this.$route.params.actionType == 'edit') {
      this.editor.isReady
        .then(() => this.fetchConfig())
    }
  }

}
